.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 5rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__chef-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
    filter: contrast(2.0);
}
.spoon-container{
    width: 39% !important ;
}
.spoon_master{
    margin-left: 50%;
    width: 50%;
    top: 33px;
    position: relative;
}
@media screen and (min-width: 2900px) {

     .spoon_master{
        width: 37% !important;
        margin-left: 50%;
     }
    
}
@media screen and (max-width: 2000px) {
    .spoon-container{
        width: 23% !important;
        margin-left: 0%;
    }
    
}
@media screen and (max-width: 1125px) {
    .spoon-container{
       display: none;
    
}
}

@media screen and (min-width: 2000px) {
    .app__chef-sign img {
        width: 370px;
    }
    
    
}

@media screen and (max-width: 450px) {
    .app__chef-sign img {
        width: 80%;
    }
}
